import { IntlShape } from 'react-intl';

export const commonTranslations = (intl: IntlShape) => ({
  common: {
    generalContact: {
      title: intl.formatMessage({
        defaultMessage: 'Contattaci',
        description: 'CommonUse generalContact title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'Compila il form per richiedere più informazioni ai nostri consulenti specializzati.',
        description: 'CommonUse generalContact subtitle',
      }),
      button: intl.formatMessage({
        defaultMessage: 'Compila il form',
        description: 'CommonUse generalContact button',
      }),
    },
    macroCategories: {
      dashboard: intl.formatMessage({
        defaultMessage: 'Dashboard',
        description: 'CommonUse dashboard category label',
      }),
      internazionalization: intl.formatMessage({
        defaultMessage: 'Internazionalità',
        description: 'CommonUse internazionalization macrocategory label',
      }),
      trainingAndConsulting: intl.formatMessage({
        defaultMessage: 'Formazione & Consulenza',
        description: 'CommonUse traning and consultin macrocategory label',
      }),
      sustainability: intl.formatMessage({
        defaultMessage: 'Sostenibilità',
        description: 'CommonUse sustainability macrocategory label',
      }),
    },
    categories: {
      studyCenter: intl.formatMessage({
        defaultMessage: 'Centro Studi',
        description: 'CommonUse studyCenter category label',
      }),
      academy: intl.formatMessage({
        defaultMessage: 'Academy',
        description: 'CommonUse academy category label',
      }),
      finance: intl.formatMessage({
        defaultMessage: 'Finanza',
        description: 'CommonUse finance category label',
      }),
      intellectualProperty: intl.formatMessage({
        defaultMessage: 'Proprietà intellettuale',
        description: 'CommonUse intellectualProperty category label',
      }),
      library: intl.formatMessage({
        defaultMessage: 'Biblioteca',
        description: 'CommonUse library category label',
      }),
      materialLibrary: intl.formatMessage({
        defaultMessage: 'Libreria Materiali',
        description: 'CommonUse materialLibrary category label',
      }),
      circularityTool: intl.formatMessage({
        defaultMessage: 'TECLA',
        description: 'CommonUse circularityTool category label',
      }),
      circularityToolSubtitle: intl.formatMessage({
        defaultMessage:
          'Misura la circolarità dei processi della tua organizzazione grazie alla specifica tecnica UNI/TS 11820:  “Misurazione della circolarità. Metodi ed indicatori per la misurazione dei processi circolari nelle organizzazioni”.',
        description: 'CommonUse circularityToolSubtitle category label',
      }),
      designWood: intl.formatMessage({
        defaultMessage: 'Bosco del Design',
        description: 'CommonUse designWood category label',
      }),
      generalSurvey: intl.formatMessage({
        defaultMessage: 'Survey Generale',
        description: 'CommonUse generalSurvey category label',
      }),
      certifications: intl.formatMessage({
        defaultMessage: 'Portale Certificazioni',
        description: 'CommonUse certifications category label',
      }),
      customHouseOffice: intl.formatMessage({
        defaultMessage: 'Sportello Doganale',
        description: 'CommonUse customHouse office category label',
      }),
      italianWoodExchange: intl.formatMessage({
        defaultMessage: 'Borsa Italiana del Legno',
        description: 'CommonUse italianWoodExchange category label',
      }),
      glossary: intl.formatMessage({
        defaultMessage: 'Glossario',
        description: 'CommonUse glossary category label',
      }),
      ai: intl.formatMessage({
        defaultMessage: 'AI concierge',
        description: 'CommonUse ai category label',
      }),
    },
    fields: {
      firstName: intl.formatMessage({
        defaultMessage: 'Nome',
        description: 'CommonUse firstName field label',
      }),
      lastName: intl.formatMessage({
        defaultMessage: 'Cognome',
        description: 'CommonUse lastName field label',
      }),
      email: intl.formatMessage({
        defaultMessage: 'Email',
        description: 'CommonUse email field label',
      }),
      phone: intl.formatMessage({
        defaultMessage: 'Telefono',
        description: 'CommonUse phone field label',
      }),
      businessName: intl.formatMessage({
        defaultMessage: 'Ragione Sociale',
        description: 'CommonUse businessName field label',
      }),
      role: intl.formatMessage({
        defaultMessage: 'Ruolo',
        description: 'CommonUse role field label',
      }),
      oldPassword: intl.formatMessage({
        defaultMessage: 'Vecchia Password',
        description: 'CommonUse oldPassword field label',
      }),
      newPassword: intl.formatMessage({
        defaultMessage: 'Nuova Password',
        description: 'CommonUse newPassword field label',
      }),
      confirmPassword: intl.formatMessage({
        defaultMessage: 'Conferma Password',
        description: 'CommonUse confirmPassword field label',
      }),
      feedback: intl.formatMessage({
        defaultMessage: 'Feedback',
        description: 'CommonUse feedback field label',
      }),
      associated: intl.formatMessage({
        defaultMessage: 'Associato',
        description: 'CommonUse associated field label',
      }),
      association: intl.formatMessage({
        defaultMessage: 'Associazione',
        description: 'CommonUse association field label',
      }),
      agency: intl.formatMessage({
        defaultMessage: 'Azienda',
        description: 'CommonUse agency field label',
      }),
      address: intl.formatMessage({
        defaultMessage: 'Indirizzo',
        description: 'CommonUse address field label',
      }),
      cap: intl.formatMessage({
        defaultMessage: 'CAP',
        description: 'CommonUse cap field label',
      }),
      city: intl.formatMessage({
        defaultMessage: 'Città',
        description: 'CommonUse city field label',
      }),
      province: intl.formatMessage({
        defaultMessage: 'Provincia',
        description: 'CommonUse province field label',
      }),
      subject: intl.formatMessage({
        defaultMessage: 'Oggetto',
        description: 'CommonUse subject field label',
      }),
      message: intl.formatMessage({
        defaultMessage: 'Messaggio',
        description: 'CommonUse message field label',
      }),
    },
    buttons: {
      viewDetails: intl.formatMessage({
        defaultMessage: 'Vedi Dettaglio',
        description: 'CommonUse viewDetails button label',
      }),
      sendFeedback: intl.formatMessage({
        defaultMessage: 'Invia Feedback',
        description: 'CommonUse sendFeedback button label',
      }),
      saveChanges: intl.formatMessage({
        defaultMessage: 'Salva Modifiche',
        description: 'CommonUse saveChanges button label',
      }),
      logout: intl.formatMessage({
        defaultMessage: 'Logout',
        description: 'CommonUse logout button label',
      }),
      openWebSite: intl.formatMessage({
        defaultMessage: 'Apri federlegnoarredo.it',
        description: 'CommonUse openWebSite button label',
      }),
      viewAll: intl.formatMessage({
        defaultMessage: 'Vedi tutti',
        description: 'CommonUse view all button label',
      }),
      sendRequest: intl.formatMessage({
        defaultMessage: 'Invia richiesta',
        description: 'CommonUse sendRequest button label',
      }),
    },
    formMessage: {
      required: intl.formatMessage({
        defaultMessage: '*campi obbligatori',
        description: 'CommonUse required text',
      }),
    },
    typeUser: {
      associated: intl.formatMessage({
        defaultMessage: 'Associato',
        description: 'CommonUse associated label',
      }),
      manager: intl.formatMessage({
        defaultMessage: 'Manager',
        description: 'CommonUse manager label',
      }),
    },
    header: {
      profileMessage: intl.formatMessage({
        defaultMessage: 'Ciao,',
        description: 'Header Profile Message label',
      }),
      notifications: {
        title: intl.formatMessage({
          defaultMessage: 'Notifiche',
          description: 'Header Notifications Menu title',
        }),
        news: intl.formatMessage({
          defaultMessage: 'NUOVI',
          description: 'Header Notifications news label',
        }),
        firstPartMessage: intl.formatMessage({
          defaultMessage: 'Hai',
          description: 'Header Notifications first part message',
        }),
        secondPartMessage: {
          singolar: intl.formatMessage({
            defaultMessage: 'comunicazione non letta',
            description: 'Header Notifications second part message',
          }),
          plural: intl.formatMessage({
            defaultMessage: 'comunicazioni non lette',
            description: 'Header Notifications second part message',
          }),
        },
      },
    },
    titles: {
      evidenceProduct: intl.formatMessage({
        defaultMessage: 'Prodotti in evidenza',
        description: 'Generic title',
      }),
    },
  },
});
