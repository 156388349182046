import { Stack, Button, useTheme } from '@mui/material';
import { MainContainer, StyledDrawer } from './AiModal.style';
import { Chat } from './sections/chat';
import { Iconify } from 'components';
import { useAiModal } from 'hooks';

const AiModal = ({ openNav }: { openNav: boolean }) => {
  const { aiModalState, closeAIModal, isExpanded } = useAiModal();
  const theme = useTheme();
  const handleCloseModal = () => {
    closeAIModal();
  };

  return (
    <StyledDrawer
      open={aiModalState}
      anchor={'bottom'}
      variant={'persistent'}
      hideBackdrop={true}
      openNav={openNav}
      expandDrawer={isExpanded}
      typing={false}
      className="ai_drawer"
    >
      <>
        <Stack
          sx={{ width: '100%' }}
          alignItems="flex-end"
          justifyContent="flex-start"
        >
          <Button
            size="small"
            color="primary"
            onClick={() => handleCloseModal()}
            endIcon={
              <Iconify
                icon="ic:outline-close"
                style={{ color: theme.palette.grey[600] }}
              />
            }
            sx={{
              minHeight: '0px',
              minWidth: '0px',
              padding: '0px 10px',
            }}
          >
            Chiudi
          </Button>
        </Stack>

        <MainContainer expandDrawer={isExpanded} typing={false}>
          {/* <Fade
            in={isExpanded}
            style={{ transitionDelay: isExpanded ? '0.3s' : '0' }}
          > */}
          <Chat />
          {/* <div>Ciao</div> */}
          {/* </Fade> */}
        </MainContainer>
      </>
    </StyledDrawer>
  );
};

export { AiModal };
