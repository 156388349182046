import { TJsonApiBody, TJsonApiData } from 'jsona/lib/JsonaTypes';
import useFetch from 'use-http';
import {
  DrupalNode,
  EntityFieldConfig,
  IAskChatInput,
  IAskChatOutput,
  IHttpMutateHelperProps,
  ITrySubRequestInput,
  JsonApiDeleteResponse,
  JsonApiHookInterface,
  JsonApiStandardBody,
  JsonApiUpdateResourceBody,
} from './types';
import { createQueryParams, deserialize, logApiError } from './utils';
import * as API from 'constants/api';
import { useAuth } from 'hooks/api';
import { useAuthSelector } from 'stores';
import { ISubmitGeneralSurveyBody } from 'types/generalSurvey';
import { IFeedbackRes, ISubmitToolSurveyBody } from 'types/tool';

import { IWebFormRes, WebformIdEnum } from 'types/webform';

const useJsonApi = ({ showLoader, hideLoader }: JsonApiHookInterface) => {
  const { get, post, patch, delete: remove } = useFetch();
  const { refreshTokens } = useAuth();
  const { remember, handleLogout } = useAuthSelector();

  const getEntity = async ({
    useLoader,
    setData,
    id,
    body = {
      entity: 'node',
      bundle: 'article',
      /**
       * URLSearchParams needs a particular structure;
       * for every key we need an array of array of string.
       * e.g.::
       * [['sortBy', 'user'], ['sortDirection', 'asc']] => ?sortBy=user&sortDirection=asc
       */
      additionalParams: [],
      nodeFieldConfig: [],
    },
  }: IHttpMutateHelperProps<
    DrupalNode,
    {
      entity: string;
      bundle: string;
      additionalParams?: string[][];
      nodeFieldConfig?: EntityFieldConfig[];
    }
  >): Promise<DrupalNode | null> => {
    const { entity, bundle, nodeFieldConfig, additionalParams } = body;
    // Handling Includes and Additional Parameters
    const params =
      nodeFieldConfig?.length || additionalParams?.length
        ? createQueryParams({ nodeFieldConfig, additionalParams })
        : '';
    // Get API Call
    try {
      useLoader && showLoader?.();
      const res: TJsonApiBody = await get(
        `/jsonapi/${entity}/${bundle}/${id}${params}`
      );
      const deserializedNode = deserialize({
        data: res.data,
        included: res.included,
      }) as DrupalNode;

      setData?.(deserializedNode);
      return deserializedNode;
    } catch (err) {
      logApiError(err, `get-${entity}-${bundle}-${id}`);
      return null;
    } finally {
      useLoader && hideLoader?.();
    }
  };

  const getEntityUrl = ({
    entity = 'node',
    bundle = 'article',
    additionalParams = [],
    nodeFieldConfig = [],
    id,
  }: {
    entity: string;
    bundle: string;
    additionalParams?: string[][];
    nodeFieldConfig?: EntityFieldConfig[];
    id: string | number;
  }) => {
    const params =
      nodeFieldConfig?.length || additionalParams?.length
        ? createQueryParams({ nodeFieldConfig, additionalParams })
        : '';
    return `${process.env.REACT_APP_HOST}/jsonapi/${entity}/${bundle}/${id}${params}`;
  };

  const listEntitiesUrl = ({
    entity = 'field_config',
    bundle = 'field_config',
    additionalParams = [],
    specificResValue = [],
  }: {
    entity: string;
    bundle: string;
    additionalParams?: string[][];
    specificResValue?: string[];
  }) => {
    const defaultParams = [
      ...(additionalParams || []),
      ['filter[status][value]', '1'],
    ];
    specificResValue?.length
      ? defaultParams?.push([
          `fields[${entity}--${bundle}]`,
          specificResValue.join(','),
        ])
      : null;
    const query = defaultParams?.length && new URLSearchParams(defaultParams);
    const params = query ? `?${new URLSearchParams(query)}` : '';
    return `${process.env.REACT_APP_HOST}/jsonapi/${entity}/${bundle}${params}`;
  };

  const listEntities = async ({
    useLoader,
    setData,
    body = {
      entity: 'field_config',
      bundle: 'field_config',
      additionalParams: [],
      specificResValue: [],
      deserializeEntities: false,
      retrieveTotalCount: undefined,
      retrieveNextPage: undefined,
      retrievePrevPage: undefined,
      disableStatusFilter: false,
    },
  }: IHttpMutateHelperProps<
    TJsonApiData[],
    {
      entity: string;
      bundle: string;
      additionalParams?: string[][];
      deserializeEntities?: boolean;
      disableStatusFilter?: boolean;
      specificResValue?: string[];
      retrieveTotalCount?: (count: number) => void;
      retrieveNextPage?: (link: string) => void;
      retrievePrevPage?: (link: string) => void;
    }
  >): Promise<TJsonApiData[] | null> => {
    const {
      entity,
      bundle,
      additionalParams,
      specificResValue,
      retrieveTotalCount,
      deserializeEntities,
      disableStatusFilter,
      retrieveNextPage,
      retrievePrevPage,
    } = body;
    const defaultParams = [
      ...(additionalParams || []),
      ...(!disableStatusFilter ? [['filter[status][value]', '1']] : []),
    ];
    specificResValue?.length
      ? defaultParams?.push([
          `fields[${entity}--${bundle}]`,
          specificResValue.join(','),
        ])
      : null;
    const query = defaultParams?.length && new URLSearchParams(defaultParams);
    const params = query ? `?${new URLSearchParams(query)}` : '';
    // Get API Call
    try {
      useLoader && showLoader?.();
      const res: TJsonApiBody & {
        meta: { count: number };
        links: { next?: { href: string }; prev?: { href: string } };
      } = await get(
        `/jsonapi/${entity}${
          bundle === 'onlyNode' ? '' : `/${bundle}`
        }${params}`
      );
      const unparsedEntities = res.data as TJsonApiData[];
      retrieveTotalCount?.(res.meta.count);
      res?.links?.next && retrieveNextPage?.(res?.links?.next.href);
      res?.links?.prev && retrievePrevPage?.(res?.links?.prev.href);
      const returnData = (
        deserializeEntities
          ? deserialize({
              data: res.data,
              included: res.included,
            })
          : unparsedEntities
      ) as TJsonApiData[];
      setData?.(returnData);
      return returnData;
    } catch (err) {
      logApiError(err, `list-${entity}-${bundle}`);
      return null;
    } finally {
      useLoader && hideLoader?.();
    }
  };

  const createEntity = async ({
    useLoader,
    body = {
      entity: 'node',
      bundle: 'article',
      input: { data: {} },
    },
  }: IHttpMutateHelperProps<
    DrupalNode,
    JsonApiUpdateResourceBody
  >): Promise<DrupalNode | null> => {
    const { entity, bundle } = body;
    try {
      useLoader && showLoader?.();
      const res: TJsonApiBody = await post(
        `/jsonapi/${entity}/${bundle}`,
        body?.input
      );
      const deserializedNode = deserialize({
        data: res.data,
        included: res.included,
      }) as DrupalNode;
      return deserializedNode;
    } catch (err) {
      logApiError(err, `create-${entity}-${bundle}`);
      return null;
    } finally {
      useLoader && hideLoader?.();
    }
  };

  const editEntity = async ({
    useLoader,
    body = { entity: 'node', bundle: 'article', input: { data: {} } },
    id,
  }: IHttpMutateHelperProps<
    DrupalNode,
    JsonApiUpdateResourceBody
  >): Promise<DrupalNode | null> => {
    const { entity, bundle } = body;
    try {
      useLoader && showLoader?.();
      const res: TJsonApiBody = await patch(
        `/jsonapi/${entity}/${bundle}/${id}`,
        body?.input
      );
      const deserializedNode = deserialize({
        data: res.data,
        included: res.included,
      }) as DrupalNode;
      return deserializedNode;
    } catch (err) {
      logApiError(err, `edit-${entity}-${bundle}-${id}`);
      return null;
    } finally {
      useLoader && hideLoader?.();
    }
  };

  const deleteEntity = async ({
    useLoader,
    body = { entity: 'node', bundle: 'article' },
    id,
  }: IHttpMutateHelperProps<
    DrupalNode,
    JsonApiStandardBody
  >): Promise<JsonApiDeleteResponse | null> => {
    const { entity, bundle } = body;
    try {
      useLoader && showLoader?.();
      await remove(`/jsonapi/${entity}/${bundle}/${id}`);
      return { success: true };
    } catch (err) {
      logApiError(err, `delete-${entity}-${bundle}`);
      return null;
    } finally {
      useLoader && hideLoader?.();
    }
  };

  const listToolWebformSubmissions = async ({
    useLoader = false,
  }: {
    useLoader: boolean;
  }): Promise<DrupalNode[] | null> => {
    try {
      useLoader && showLoader?.();
      const response = await get(
        `/jsonapi/webform_submission/circularity_tool?include=uid`
      );
      const data = response as TJsonApiBody;

      const deserializedNode = deserialize({
        data: data.data,
        included: data.included,
      }) as DrupalNode[];
      return deserializedNode;
    } catch (err) {
      logApiError(err, `list-tool-webform-submissions`);
      return null;
    } finally {
      useLoader && hideLoader?.();
    }
  };

  const submitToolSurvey = async ({
    useLoader = false,
    body,
    id,
  }: IHttpMutateHelperProps<
    string,
    ISubmitToolSurveyBody
  >): Promise<string> => {
    if (!body) return '';
    try {
      useLoader && showLoader?.();
      let resId = '';
      if (!id) {
        const postRes = await post(`/webform_rest/submit`, body);
        resId = postRes.sid;
      } else {
        const castedBody: Partial<ISubmitToolSurveyBody> = body;
        delete castedBody.webform_id;
        await patch(
          `/webform_rest/circularity_tool/submission/${id}?_format=json`,
          castedBody
        );
        resId = id.toString();
      }
      return resId;
    } catch (err) {
      logApiError(err, `submit-survey`);
      return '';
    } finally {
      useLoader && hideLoader?.();
    }
  };

  const submitGeneralSurvey = async ({
    useLoader = false,
    body,
    id,
  }: IHttpMutateHelperProps<
    string,
    ISubmitGeneralSurveyBody
  >): Promise<string> => {
    if (!body) return '';
    try {
      useLoader && showLoader?.();
      let resId = '';
      if (!id) {
        const postRes = await post(`/webform_rest/submit`, body);
        resId = postRes.sid;
      } else {
        const castedBody: Partial<ISubmitGeneralSurveyBody> = body;
        const webformId = castedBody.webform_id;
        delete castedBody.webform_id;
        await patch(
          `/webform_rest/${webformId}/submission/${id}?_format=json`,
          castedBody
        );
        resId = id.toString();
      }
      return resId;
    } catch (err) {
      logApiError(err, `submit-general-survey`);
      return '';
    } finally {
      useLoader && hideLoader?.();
    }
  };

  const fetchFeedback = async ({
    useLoader = false,
    body,
  }: IHttpMutateHelperProps<
    boolean,
    { [key: string]: string }
  >): Promise<IFeedbackRes | null> => {
    if (!body) return null;
    try {
      useLoader && showLoader?.();
      const res = (await post(
        `/api/fla/circularity-tool/totals`,
        body
      )) as IFeedbackRes;
      return res;
    } catch (err) {
      logApiError(err, `fetch-feedback`);
      return null;
    } finally {
      useLoader && hideLoader?.();
    }
  };

  const getWebform = async ({
    useLoader,
    setData,
    id,
    body = {
      webformId: `${WebformIdEnum}`,
    },
  }: IHttpMutateHelperProps<
    IWebFormRes,
    {
      webformId: string;
    }
  >): Promise<IWebFormRes | null> => {
    const { webformId } = body;
    try {
      useLoader && showLoader?.();
      const res: IWebFormRes = await get(
        `/webform_rest/${webformId}/${id ? `submission/${id}` : 'fields'}`
      );

      setData?.(res);
      return res;
    } catch (err) {
      logApiError(err, `get-webform_rest-${webformId}`);
      return null;
    } finally {
      useLoader && hideLoader?.();
    }
  };

  const submitWebform = async ({
    useLoader,
    body,
  }: IHttpMutateHelperProps<
    boolean,
    { [key: string]: string | { [key: string]: string }[] }
  >): Promise<boolean> => {
    try {
      useLoader && showLoader?.();
      await post(`/webform_rest/submit`, body);
      return true;
    } catch (err) {
      logApiError(err, `submit-webform`);
      return false;
    } finally {
      useLoader && hideLoader?.();
    }
  };

  const getCountriesList = async ({
    useLoader,
    type = 'node',
    bundle = 'furniture',
  }: {
    useLoader: boolean;
    type: string;
    bundle: string;
  }): Promise<string[]> => {
    // TODO: maybe it can be moved to Lambda Admin Auth
    try {
      useLoader && showLoader?.();
      const res = await get(
        `/api/fla/countries/list/${type}/${bundle}_certification`
      );
      return res;
    } catch (err) {
      logApiError(err, `get-countrieslists`);
      return [];
    } finally {
      useLoader && hideLoader?.();
    }
  };

  const trySubrequest = async ({
    accessToken,
    blueprint,
    toBeDeserialized = true,
  }: ITrySubRequestInput): Promise<null | DrupalNode[][] | void> => {
    const subRes = await fetch(
      `${process.env.REACT_APP_HOST}/subrequests?_format=json`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(
          blueprint.map(b => ({
            ...b,
            headers: { ...b.headers, Authorization: `Bearer ${accessToken}` },
          }))
        ),
      }
    );
    if (subRes && subRes.ok) {
      const data: { [key: string]: { body: string } } = await subRes.json();
      const parsed = Object.values(data).map(d => {
        const parsedBody = d.body
          ? JSON.parse(d.body)
          : ({ data: [], included: [], meta: { count: 0 } } as TJsonApiBody & {
              meta?: { count?: number };
            });
        const isList = !!parsedBody?.meta?.count;
        if (isList) {
          const deserializedNode = deserialize({
            data: parsedBody.data,
            included: parsedBody.included,
          })?.map((d: DrupalNode) => ({
            ...d,
            count: parsedBody?.meta?.count || 1,
          })) as DrupalNode[];
          return deserializedNode;
        } else {
          const deserializedNode = toBeDeserialized
            ? (deserialize({
                data: parsedBody.data,
                included: parsedBody.included,
              }) as DrupalNode)
            : (parsedBody as DrupalNode);
          return [deserializedNode];
        }
      });
      return parsed;
    } else {
      if (subRes.status === 401) {
        if (remember) {
          const newToken = await refreshTokens();
          if (newToken && newToken.access_token) {
            const newRes = await fetch(
              `${process.env.REACT_APP_HOST}/subrequests?_format=json`,
              {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${newToken.access_token}`,
                },
                body: JSON.stringify(
                  blueprint.map(b => ({
                    ...b,
                    headers: {
                      ...b.headers,
                      Authorization: `Bearer ${newToken.access_token}`,
                    },
                  }))
                ),
              }
            );
            if (newRes && newRes.ok) {
              const data: { [key: string]: { body: string } } =
                await newRes.json();
              const parsed = Object.values(data).map(d => {
                const parsedBody = d.body
                  ? JSON.parse(d.body)
                  : ({
                      data: [],
                      included: [],
                      meta: { count: 0 },
                    } as TJsonApiBody & {
                      meta?: { count?: number };
                    });
                const isList = !!parsedBody?.meta?.count;
                if (isList) {
                  const deserializedNode = deserialize({
                    data: parsedBody.data,
                    included: parsedBody.included,
                  })?.map((d: DrupalNode) => ({
                    ...d,
                    count: parsedBody?.meta?.count || 1,
                  })) as DrupalNode[];
                  return deserializedNode;
                } else {
                  const deserializedNode = toBeDeserialized
                    ? (deserialize({
                        data: parsedBody.data,
                        included: parsedBody.included,
                      }) as DrupalNode)
                    : (parsedBody as DrupalNode);
                  return [deserializedNode];
                }
              });
              return parsed;
            }
          }
        } else {
          handleLogout();
        }
      }
    }
  };

  const updateUserImage = async ({
    useLoader = false,
    userUid,
    filename,
    file,
    accessToken,
  }: {
    useLoader: boolean;
    userUid: string;
    filename: string;
    file: File;
    accessToken: string;
  }) => {
    useLoader && showLoader?.();
    const res = await fetch(
      `${process.env.REACT_APP_HOST}/jsonapi/user/user/${userUid}/user_picture`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/octet-stream',
          Accept: 'application/vnd.api+json',
          'Content-Disposition': `file; filename="${filename}"`,
          Authorization: `Bearer ${accessToken}`,
        },
        body: file,
      }
    );

    if (res && res.ok) {
      const { data } = await res.json();
      const deserializedRes = deserialize({
        data: data,
        included: [],
      }) as DrupalNode;
      return deserializedRes.uri.url as string;
    } else {
      return null;
    }
  };

  const editUser = async ({
    useLoader = false,
    id,
    body,
    accessToken,
  }: {
    useLoader: boolean;
    id: string;
    accessToken: string;
    body: object;
  }): Promise<boolean> => {
    try {
      useLoader && showLoader?.();
      const response = await fetch(API.UPDATE_USER_DRUPAL, {
        body: JSON.stringify({
          input: body,
          uid: id,
          accessToken: accessToken,
        }),
        method: 'POST',
      });
      return response && response.ok;
    } catch (err) {
      logApiError(err, `edit-user`);
      return false;
    } finally {
      useLoader && hideLoader?.();
    }
  };

  const listUsers = async ({
    useLoader = false,
    body,
  }: {
    useLoader: boolean;
    body: object;
  }): Promise<DrupalNode[] | null> => {
    try {
      useLoader && showLoader?.();
      const response = await fetch(API.LIST_USERS_DRUPAL, {
        body: JSON.stringify(body),
        method: 'POST',
      });
      const apiResponse = await response.json();
      const data = apiResponse.data as TJsonApiBody;

      const deserializedNode = deserialize({
        data: data.data,
        included: data.included,
      }) as DrupalNode[];
      return deserializedNode;
    } catch (err) {
      logApiError(err, `list-users`);
      return null;
    } finally {
      useLoader && hideLoader?.();
    }
  };

  const askChat = async (input: IAskChatInput, signal?: AbortSignal) => {
    try {
      const response = await fetch(process.env.REACT_APP_HOST_AI as string, {
        body: JSON.stringify(input),
        method: 'POST',
        signal,
      });
      const apiResponse = (await response.json()) as IAskChatOutput;
      return apiResponse;
    } catch (err) {
      logApiError(err, `ask-chat`);
      throw { name: 'ApiCallError' };
    }
  };

  const listSurveys = async ({
    useLoader = false,
  }: {
    useLoader: boolean;
  }): Promise<DrupalNode[] | null> => {
    try {
      useLoader && showLoader?.();
      const response = await fetch(API.LIST_SURVEYS_DRUPAL, {
        method: 'POST',
      });
      const apiResponse = await response.json();
      const data = apiResponse.data as TJsonApiBody;
      const deserializedNode = deserialize({
        data: data.data,
        included: data.included,
      }) as DrupalNode[];
      return deserializedNode;
    } catch (err) {
      logApiError(err, `list-surveys`);
      return null;
    } finally {
      useLoader && hideLoader?.();
    }
  };

  const createCheckoutSession = async ({
    useLoader = true,
    cart,
    isLocalEnv = false,
  }: {
    useLoader?: boolean;
    isLocalEnv: boolean;
    cart: { id: string; quantity: number; data?: object }[];
  }) => {
    try {
      useLoader && showLoader?.();
      const response = await fetch(API.STRIPE_CHECKOUT, {
        body: JSON.stringify({
          input: cart,
          isLocalEnv,
        }),
        method: 'POST',
      });
      const apiResponse = await response.json();
      return apiResponse;
    } catch (error) {
      logApiError(error, `create-checkout-session`);
      return null;
    } finally {
      useLoader && hideLoader?.();
    }
  };

  const generateListEntitiesBlueprint = ({
    entityBundle,
    additionalParams,
    specificResValue,
    idx,
    action = 'view',
  }: {
    entityBundle: string;
    additionalParams?: string[][];
    specificResValue?: string[];
    idx: number;
    action?: 'view' | 'create' | 'update';
  }) => ({
    requestId: `req-${idx}`,
    action: action,
    uri: listEntitiesUrl({
      entity: entityBundle.split('--')[0],
      bundle: entityBundle.split('--')[1],
      ...(specificResValue?.length && {
        specificResValue: specificResValue,
      }),
      ...(additionalParams?.length && {
        additionalParams: additionalParams,
      }),
    }),
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
    },
  });

  const generateGetEntityBlueprint = ({
    entityBundle,
    additionalParams,
    nodeFieldConfig,
    id,
    idx,
    action = 'view',
  }: {
    entityBundle: string;
    additionalParams?: string[][];
    nodeFieldConfig?: EntityFieldConfig[];
    idx: number;
    action?: 'view' | 'create' | 'update';
    id: string | number;
  }) => ({
    requestId: `req-${idx}`,
    action: action,
    uri: getEntityUrl({
      entity: entityBundle.split('--')[0],
      bundle: entityBundle.split('--')[1],
      ...(nodeFieldConfig?.length && {
        nodeFieldConfig: nodeFieldConfig,
      }),
      ...(additionalParams?.length && {
        additionalParams: additionalParams,
      }),

      id,
    }),
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
    },
  });

  const generateActionEntityBlueprint = ({
    idx,
    action,
    bundle,
    entity,
    id,
    body,
  }: {
    idx: number;
    action: 'view' | 'delete' | 'create' | 'update';
    bundle: string;
    entity: string;
    id: string;
    body: object;
  }) => ({
    requestId: `req-${idx}`,
    action: action,
    uri: getEntityUrl({ bundle, entity, id }),
    ...(Object.keys(body).length > 0 && {
      body: JSON.stringify(body),
    }),
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
    },
  });

  return {
    getEntity,
    getEntityUrl,
    getWebform,
    listEntities,
    listEntitiesUrl,
    createEntity,
    editEntity,
    deleteEntity,
    submitWebform,
    getCountriesList,
    trySubrequest,
    updateUserImage,
    editUser,
    generateListEntitiesBlueprint,
    generateGetEntityBlueprint,
    generateActionEntityBlueprint,
    listUsers,
    listSurveys,
    createCheckoutSession,
    listToolWebformSubmissions,
    submitToolSurvey,
    submitGeneralSurvey,
    fetchFeedback,
    askChat,
  };
};

export { useJsonApi };
