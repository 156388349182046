import { IntlShape } from 'react-intl';
import { academyTranslations } from './academy';
import { authTranslations } from './auth';
import { cartTranslations } from './cart';
import { chatTranslations } from './chat';
import { checkoutTranslations } from './checkout';
import { commonTranslations } from './common';
import { contentTranslations } from './content';
import { dashboardTranslations } from './dashboard';
import { faqTranslations } from './faq';
import { financeTranslations } from './finance';
import { generalTranslations } from './general';
import { intellectualTranslations } from './intellectualProperty';
import { internazionalizationTranslations } from './internazionalization';
import { materialTranslations } from './material';
import { notificationsTranslation } from './notification';
import { studyCenterTranslations } from './studyCenter';
import { surveyTranslations } from './survey';
import { sustainabilityTranslations } from './sustainability';
import { toolTranslations } from './tool';
import { trainingAndConsultingTranslations } from './trainingAndConsulting';

export const translations = (intl: IntlShape) => ({
  ...authTranslations(intl),
  ...generalTranslations(intl),
  ...commonTranslations(intl),
  ...dashboardTranslations(intl),
  ...faqTranslations(intl),
  ...internazionalizationTranslations(intl),
  ...trainingAndConsultingTranslations(intl),
  ...sustainabilityTranslations(intl),
  ...contentTranslations(intl),
  ...academyTranslations(intl),
  ...materialTranslations(intl),
  ...financeTranslations(intl),
  ...intellectualTranslations(intl),
  ...studyCenterTranslations(intl),
  ...toolTranslations(intl),
  ...surveyTranslations(intl),
  ...notificationsTranslation(intl),
  ...cartTranslations(intl),
  ...checkoutTranslations(intl),
  ...chatTranslations(intl),
});
