import { IntlShape } from 'react-intl';

const htmlParserOptions = {
  br: () => <br />,
};

export const chatTranslations = (intl: IntlShape) => ({
  chat: {
    title: intl.formatMessage({
      defaultMessage: 'AI Concierge',
      description: 'chat title',
    }),
    subtitle: intl.formatMessage({
      defaultMessage:
        "Hai bisogno di supporto? Chiedi all'assistente di Fla Plus.",
      description: 'chat subtitle',
    }),
    questionOne: intl.formatMessage(
      {
        defaultMessage:
          'Dammi le novità legali e fiscali <br></br>in materia ecologica.',
        description: 'chat questionOne',
      },
      { ...htmlParserOptions }
    ),
    questionTwo: intl.formatMessage({
      defaultMessage:
        'Quali sono i corsi relativi alla sostenibilità ecologica?',
      description: 'chat questionTwo',
    }),
    search: intl.formatMessage({
      defaultMessage: 'Scrivi qui...',
      description: 'chat search',
    }),
  },
});
