import { create } from 'zustand';

interface IUseAiModalProps {
  aiModalState: boolean;
  isExpanded: boolean;
  openAIModal: VoidFunction;
  closeAIModal: VoidFunction;
}

const useAiModal = create<IUseAiModalProps>(set => ({
  aiModalState: false,
  isExpanded: true,
  openAIModal: () => set(state => ({ ...state, aiModalState: true })),
  closeAIModal: () => set(state => ({ ...state, aiModalState: false })),
}));

export { useAiModal };
