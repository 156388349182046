import { Box, styled } from '@mui/material';
import { Image } from 'components/Image/Image';

export const StyledMessagesWrapper = styled(Box)({
  flex: 1,
  height: '100%',
});

export const StyledLogo = styled(Image)(({ theme }) => ({
  width: 50,
  borderRadius: theme.spacing(1),
}));
