import { Drawer, Stack, Typography } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  RootStyle,
  Container,
  NavBarItem,
  LogoContainer,
  YellowIcon,
} from './NavbarVertical.style';
import {
  ICategoriesProps,
  INavbarVerticalProps,
  INavItemProps,
} from './NavbarVertical.types';
import { LogoSmall } from 'assets';
import { FooterLogoIcon } from 'assets/icons';
import { NAVBAR } from 'config';
import { aiItems, navBarItem } from 'constants/data';
import * as PATHS from 'constants/paths';
import { useTranslations, useResponsive } from 'hooks';
import useCollapseDrawer from 'hooks/useCollapseDrawer';
import { useGlossary } from 'hooks/useGlossary';
import cssStyles from 'utils/cssStyles';

export default function NavbarVertical({
  isOpenSidebar,
  onCloseSidebar,
  clickGlossary,
  clickAI,
}: INavbarVerticalProps) {
  //hooks
  const theme = useTheme();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const i18n = useTranslations();
  const isDesktop = useResponsive('up', 'lg');
  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();
  const { reduceGlossary, setTyping } = useGlossary();
  const [hoveredIcon, setHoveredIcon] = useState('');
  //effects
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
      setTyping(false);
    }
  }, [pathname]);

  //function
  const goToPage = (path: string) => {
    reduceGlossary();
    setTyping(false);
    navigate(path);
  };

  //renders
  const renderBlockNavItem = ({
    items,
  }: // text,
  // color,
  {
    items: INavItemProps[];
    // text: string;
    // color: string;
  }) => {
    const categories: ICategoriesProps = i18n.common.categories;

    return (
      <div
        style={{
          position: 'relative',
        }}
      >
        {items?.map((el, index) => {
          const Icon = el.icon;
          return (
            <NavBarItem
              isCollapse={isCollapse}
              key={`navItem-${index}`}
              active={pathname === el.path && !el.disabled}
              style={{
                marginBottom: 20,
                ...(el.disabled && {
                  cursor: 'default',
                  color: '#454F5B',
                }),
              }}
              onMouseEnter={() => setHoveredIcon(el.label)}
              onMouseLeave={() => setHoveredIcon('')}
            >
              <Typography
                variant="body2"
                onClick={() => !el.disabled && goToPage(el.path)}
              >
                <div
                  style={{
                    width: '32px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Icon
                    color={
                      el.disabled
                        ? '#454F5B'
                        : el.path === pathname
                        ? '#DCB900'
                        : el.label === hoveredIcon
                        ? theme?.palette.grey[400]
                        : '#919EAB'
                    }
                    style={{ width: '32px', height: '33px' }}
                  />
                </div>
              </Typography>
              {!isCollapse && (
                <Typography
                  variant="body2"
                  onClick={() => !el.disabled && goToPage(el.path)}
                  color={
                    el.disabled
                      ? '#454F5B'
                      : el.path === pathname
                      ? '#FFF'
                      : el.label === hoveredIcon
                      ? theme?.palette.grey[400]
                      : '#919EAB'
                  }
                >
                  {categories[el.ref]}
                </Typography>
              )}
            </NavBarItem>
          );
        })}
      </div>
    );
  };

  const renderContent = (
    <Container isCollapse={isCollapse} id="navbar-fla-vertical">
      <LogoContainer
        isCollapse={isCollapse}
        onClick={() => navigate(PATHS.DASHBOARD)}
      >
        <LogoSmall
          sx={{
            width: '41px',
            position: 'absolute',
            opacity: isCollapse ? 1 : 0,
            transition: isCollapse ? 'all 0.5s 0.2s ease' : 'all 0.0s ease',
          }}
        />

        <FooterLogoIcon
          style={{
            width: '150px',
            position: 'absolute',
            opacity: !isCollapse ? 1 : 0,
            transition: !isCollapse ? 'all 0.5s 0.2s ease' : 'all 0.0s ease',
          }}
        />
      </LogoContainer>
      <Stack
        sx={{
          marginTop: !isCollapse ? '65px' : '75px',
          width: '100%',
        }}
      >
        {renderBlockNavItem({
          items: navBarItem.slice(1, 12),
        })}
        {[navBarItem[navBarItem.length - 1]].map((el, index) => {
          const Icon = el.icon;

          return (
            <div key={`yellowItem${index}`}>
              <NavBarItem
                isCollapse={isCollapse}
                active={pathname === el.path}
                onClick={clickGlossary}
                style={{ alignItems: 'center' }}
              >
                <div
                  style={{
                    width: '32px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Icon />
                </div>
                {!isCollapse && (
                  <Typography variant="body2">
                    {i18n.common.categories.glossary}
                  </Typography>
                )}
              </NavBarItem>
            </div>
          );
        })}
        {aiItems.map((el, index) => {
          const Icon = el.icon;

          return (
            <div style={{ marginTop: '20px' }} key={`yellowItem${index}`}>
              <NavBarItem
                isCollapse={isCollapse}
                active={pathname === el.path}
                onClick={clickAI}
                style={{ alignItems: 'center' }}
              >
                <YellowIcon isCollapse={isCollapse}>
                  <Icon color="#fff"></Icon>
                  {!isCollapse && (
                    <Typography
                      variant="body2"
                      marginLeft={'8px'}
                      color={'white'}
                    >
                      {i18n.common.categories.ai}
                    </Typography>
                  )}
                </YellowIcon>
              </NavBarItem>
            </div>
          );
        })}
      </Stack>
    </Container>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          sx={{ zIndex: 99999 }}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              zIndex: 5000,
              transition: theme =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: '0px 0px 10px black',
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
