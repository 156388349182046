import { Box, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ChatInput } from './chat-input';
import { ChatMessages } from './chat-messages';
import {
  StyledChatWrapper,
  StyledPrompt,
  StyledResetContextButton,
} from './chat.styles';
import { StartingChat } from './starting-chat';
import { Iconify } from 'components/Iconify/Iconify';
import { IAskChatInput, useJsonApi, useTranslations } from 'hooks';
import { FormProvider } from 'providers';
import { useGlobalStore } from 'stores';
import { IMessage } from 'types/chat';

interface IChatFormValues {
  prompt: string;
}
const defaultValues = { prompt: '' };

const Chat = () => {
  // refs
  const lastQuestionRef = useRef<HTMLDivElement>(null);
  const { showLoader, hideLoader } = useGlobalStore();
  const { askChat } = useJsonApi({ showLoader, hideLoader });
  // hooks
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const i18n = useTranslations();
  const form = useForm<IChatFormValues>({ defaultValues });
  const { handleSubmit, reset } = form;

  // state
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [pending, setPending] = useState(false);
  const [thread, setThread] = useState('');
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  // effects
  useEffect(() => {
    const lastQuestionEl = lastQuestionRef.current;
    if (!lastQuestionEl) return;
    lastQuestionEl?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const hasMessages = messages.length;

  // functions
  const addMessage = (data: IMessage, skipLoading?: boolean) => {
    setMessages(prev => [...prev, data]);
    !skipLoading && setPending(true);
  };

  const onSubmit = async ({ prompt }: IChatFormValues) => {
    abortController?.abort();
    addMessage({ message: prompt, isUser: true });
    reset();

    const askInput: IAskChatInput = {
      query: prompt,
      thread,
    };

    try {
      const controller = new AbortController();
      const { signal } = controller;
      setAbortController(controller);
      const res = await askChat(askInput, signal);

      addMessage(res);
      setThread(res.thread_id || '');
      setPending(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err.name === 'AbortError') return;
      console.error(err);
      setPending(false);
    }
  };

  const handleSearch = handleSubmit(onSubmit);

  const handleCancel = () => {
    setPending(false);
    abortController?.abort();
  };

  // renders
  const Prompt = () => (
    <StyledPrompt>
      <FormProvider methods={form} onSubmit={handleSearch}>
        <ChatInput
          name="prompt"
          rules={{ required: true }}
          placeholder={i18n.chat.search}
          pending={pending}
          onCancel={handleCancel}
          onSearch={handleSearch}
        />
      </FormProvider>
      <StyledResetContextButton
        size="large"
        disabled={!thread}
        onClick={() => setThread('')}
      >
        <Tooltip title="Reset contesto chat" placement="top" arrow>
          <Iconify icon="solar:chat-square-arrow-bold" />
        </Tooltip>
      </StyledResetContextButton>
    </StyledPrompt>
  );

  return (
    <StyledChatWrapper>
      <Box flex={1} maxHeight="calc(100% - 100px)">
        {hasMessages ? (
          <ChatMessages
            messages={messages}
            pending={pending}
            lastQuestionRef={lastQuestionRef}
          />
        ) : (
          <StartingChat
            onQuestionClick={prompt =>
              onSubmit({ prompt: prompt.replace('<br>', '') })
            }
          />
        )}
      </Box>
      <Prompt />
    </StyledChatWrapper>
  );
};

export { Chat };
