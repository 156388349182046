import { Paper, Stack, styled } from '@mui/material';
import { Image } from 'components/Image/Image';

export const StyledStartingChatWraper = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBlock: theme.spacing(18),
}));

export const StyledStartingQuestion = styled(Paper)(({ theme }) => ({
  borderRadius: '8px !important',
  padding: `${theme.spacing(4)} !important`,
  width: '100%',
  cursor: 'pointer',
  backgroundColor: '#EFEEEA !important',
  boxShadow: 'none !important',
  color: '#212B36',
  marginRight: '0px !important',
  '&:hover': {
    transition: 'all 0.2s ease-out',
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[10],
  },
}));

export const StyledLogo = styled(Image)(({ theme }) => ({
  width: 70,
  borderRadius: theme.spacing(1),
  margin: 'auto',
}));
