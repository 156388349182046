import { styled, Drawer, Button, TableCell } from '@mui/material';
import {
  ILetterProps,
  IMainContainerProps,
  IStyledDrawer,
  SelectionSizes,
} from './AiModal.types';
import { NAVBAR } from 'config';

export const StyledDrawer = styled(Drawer, {
  shouldForwardProp: prop =>
    !['openNav', 'expandDrawer', 'typing'].includes(prop as string),
})<IStyledDrawer>(({ theme, expandDrawer, openNav, typing }) => ({
  '& .MuiPaper-root': {
    height: typing ? '50%' : expandDrawer ? '88%' : '120px',
    width: !openNav
      ? 'calc(100% - 265px)'
      : `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH + 83}px)`,
    backgroundColor: theme?.palette.grey[0],
    right: '0px !important',
    left: 'auto',
    boxShadow: '0px 7px 15px rgba(0, 0, 0, 0.7)',
    borderRadius: '14px 14px 14px 14px',
    padding: ' 10px 20px',
    overflow: 'auto',
    marginBottom: '10px',
    marginRight: !openNav ? 0 : 16 + 25,
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1) !important',

    [`@media (max-width: ${theme?.breakpoints.values.lg}px)`]: {
      width: `calc(100% - 50px)`,
      marginRight: 16 + 10,
    },
    [`@media (max-width: 768px)`]: {
      height: '100vh',
      width: '100%',
      margin: 0,
      overflow: 'hidden',
      borderRadius: '0px',
    },
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: '14px',
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.grey[0],
  borderRadius: '30px',
  display: 'flex',
  gap: '10px',
  padding: '0px 15px',
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
}));

export const MainContainer = styled('div', {
  shouldForwardProp: prop =>
    !['expandDrawer', 'typing'].includes(prop as string),
})<IMainContainerProps>(({ expandDrawer, typing }) => ({
  width: '100%',
  height: expandDrawer || typing ? '88%' : '0',
  transition: 'height 0.3s cubic-bezier(0.4, 0, 0.2, 1) !important',
  display: 'flex',
  gap: '0px',
}));

export const Letter = styled(Button, {
  shouldForwardProp: prop =>
    !['active', 'hoverIndex', 'index'].includes(prop as string),
})<ILetterProps>(({ active, theme, hoverIndex, index }) => {
  const hoverOffset: SelectionSizes = Math.abs(
    index - (hoverIndex as number)
  ) as SelectionSizes;

  const defaultSize = 15;
  const defaultFontSize = 10;

  const sizeValues = {
    [SelectionSizes.LARGE]: 35,
    [SelectionSizes.MEDIUM]: 30,
    [SelectionSizes.SMALL]: 20,
  };

  const fontValues = {
    [SelectionSizes.LARGE]: 16,
    [SelectionSizes.MEDIUM]: 14,
    [SelectionSizes.SMALL]: 12,
  };

  const size: number = active
    ? sizeValues[SelectionSizes.LARGE]
    : sizeValues[hoverOffset] || defaultSize;

  return {
    height: size,
    width: size,
    minHeight: size,
    minWidth: size,
    borderRadius: '100%',
    backgroundColor: active ? theme?.palette.secondary.main : 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.2s linear',
    color: active ? theme?.palette.grey[0] : theme?.palette.grey[500],
    fontWeight: active ? 700 : 400,
    fontSize: active
      ? fontValues[SelectionSizes.LARGE]
      : fontValues[hoverOffset] || defaultFontSize,
    '&:hover': {
      backgroundColor: active
        ? theme?.palette.warning.dark
        : theme?.palette.grey[300],
      color: theme?.palette.grey[800],
    },
  };
});

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: 'white !important',
  backgroundImage: 'unset !important',
  fontSize: '14px',
  color: theme?.palette.primary.main,
  width: 'calc(100% / 7)',
}));

export const ContainerScroll = styled('div')(
  ({ typing = false }: { typing: boolean }) => ({
    height: typing ? '82%' : '94%',
    width: '100%',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '6px',
      margiRight: '2px !important',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#63738148',
      borderRadius: '1000px !important',
    },
    [`@media (max-width: 768px)`]: {
      height: '95%',
      marginTop: 30,
      marginBottom: 50,
    },
  })
);

export const CompressButtonContainer = styled('div')({
  position: 'absolute',
  top: 20,
  right: 20,
});

export const StyledMobileResultCard = styled('div')({
  borderRadius: 10,
  backgroundColor: 'white',
  border: '1px solid #F4F6F8',
  boxShadow: '0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
  minHeight: 209,
  maxWidth: 345,
  margin: '8px',
  padding: '23px 0',
  [`@media (max-width: 600px)`]: {
    width: '96%',
    maxWidth: '100%',
    marginLeft: 0,
    marginRight: 0,
  },
});
