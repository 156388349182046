import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { IMarkdownProps } from './Markdown.types';

export const Markdown = ({ source, ...rest }: IMarkdownProps) => {
  return (
    <ReactMarkdown remarkPlugins={[remarkGfm]} {...rest}>
      {source}
    </ReactMarkdown>
  );
};
