import { IconButton, InputBase, styled } from '@mui/material';

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  backgroundColor: 'white',
  height: '100%',
  borderRadius: '8px',
  padding: 16,
  border: '1px solid rgba(145, 158, 171, 0.42)',
  '& input': {
    fontSize: theme.typography.h6.fontSize,
  },
}));

export const StyleActionIcon = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: 32,
  width: 32,
  borderRadius: '8px',
  color: 'white',

  '&.Mui-disabled': {
    backgroundColor: theme.palette.grey.A400,
  },

  '&:hover': {
    backgroundColor: theme.palette.grey[800],
  },

  '& svg': {
    color: 'white',
    fill: 'white',
  },
}));
