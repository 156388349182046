import { Controller, useFormContext } from 'react-hook-form';
import { StyleActionIcon, StyledInputBase } from './chat-input.styles';
import { IChatInputProps } from './chat-input.types';
import { AiArrowIcon, AiStopIcon } from 'assets/icons';

const ChatInput = ({
  name,
  rules,
  pending,
  onCancel,
  onSearch,
  ...inputBaseProps
}: IChatInputProps) => {
  const { control } = useFormContext();

  const isCurrentlyCancelable = pending && onCancel;

  const AdornmentComp = isCurrentlyCancelable
    ? () => <AiStopIcon />
    : () => <AiArrowIcon />;

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <StyledInputBase
            {...field}
            fullWidth
            error={!!error}
            autoFocus
            endAdornment={
              <StyleActionIcon
                disabled={!field.value && !pending}
                onClick={isCurrentlyCancelable ? onCancel : onSearch}
              >
                <AdornmentComp />
              </StyleActionIcon>
            }
            {...inputBaseProps}
          />
        )}
      />
    </>
  );
};

export { ChatInput };
