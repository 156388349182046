import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
// hooks
import { HEADER, NAVBAR } from '../../config';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useSettings from '../../hooks/useSettings';
// config
//
import Footer from './footer';
import DashboardHeader from './header';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import NavbarVertical from './navbar/NavbarVertical';
import { GlossarySearchBar } from 'components';
import { AiModal } from 'components/AiModal/AiModal';
import { useAiModal, useResponsive } from 'hooks';
import { useGlossary } from 'hooks/useGlossary';

// ----------------------------------------------------------------------

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: prop => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  backgroundColor: '#EFEEEA',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 110,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `100%`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
  [theme.breakpoints.down('md')]: {
    paddingTop: HEADER.MOBILE_HEIGHT,
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const { themeLayout } = useSettings();

  const { openGlossary } = useGlossary();
  const { openAIModal } = useAiModal();
  const isDesktop = useResponsive('up', 'lg');
  const isMdDown = useResponsive('down', 'md');

  const [open, setOpen] = useState(false);
  const verticalLayout = themeLayout === 'vertical';

  const handleDrawerOpenOnMobile = () => {
    if (!isMdDown) return openGlossary();
    setOpen(false);
    openGlossary();
  };

  const handleAIDrawerOpenOnMobile = () => {
    if (!isMdDown) return openAIModal();
    setOpen(false);
    openAIModal();
  };

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader
          onOpenSidebar={() => setOpen(true)}
          verticalLayout={verticalLayout}
        />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical
            isOpenSidebar={open}
            onCloseSidebar={() => setOpen(false)}
          />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <div>
      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        <DashboardHeader
          isCollapse={isCollapse}
          onOpenSidebar={() => setOpen(true)}
        />
        <NavbarVertical
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
          clickGlossary={handleDrawerOpenOnMobile}
          clickAI={handleAIDrawerOpenOnMobile}
        />
      </Box>

      <MainStyle collapseClick={false || collapseClick}>
        {/* {isNotDashboard && isMobile ? <MobileBlockContent /> : <Outlet />} */}
        <Outlet />
        <GlossarySearchBar openNav={isCollapse || false} />
        <AiModal openNav={isCollapse || false} />
      </MainStyle>
      <Box
        sx={{
          display: { lg: 'flex' },
          justifyContent: 'flex-end',
          marginTop: 'auto',
        }}
      >
        <Footer />
      </Box>
    </div>
  );
}
