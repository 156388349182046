// @mui

import { CSSProperties } from 'react';

// ----------------------------------------------------------------------

function AiArrowIcon({
  color = 'white',
  style,
}: {
  color?: string;
  style?: CSSProperties;
}) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M10 3.83643L8.82501 5.01143L13.475 9.66976H3.33334V11.3364H13.475L8.82501 15.9948L10 17.1698L16.6667 10.5031L10 3.83643Z"
        fill={color}
      />
    </svg>
  );
}

export default AiArrowIcon;
