import { Stack, Typography } from '@mui/material';
import { m } from 'framer-motion';
import { ChatMessage } from '../chat-message';
import { StyledLogo, StyledMessagesWrapper } from './chat-messages.styles';
import { IChatMessagesProps } from './chat-messages.types';
import { Scrollbar } from 'components/ScrollBar/ScrollBar';
import { MotionContainer, varBounce } from 'components/animate';

const ChatMessages = ({
  pending,
  messages,
  lastQuestionRef,
}: IChatMessagesProps) => {
  // renders
  const Loading = () => (
    <Stack direction="row" gap={3} alignItems="center" mb={10} ml={4}>
      <m.div variants={varBounce().infinite}>
        <StyledLogo src="/logo/ai-icon.png" width={50} flexShrink={0} />
      </m.div>
      <Typography>Analisi in corso</Typography>
    </Stack>
  );

  return (
    <MotionContainer style={{ height: '100%' }}>
      <Scrollbar sx={{ width: '100%', flex: 1, height: '100%' }}>
        <StyledMessagesWrapper>
          {messages.map((m, idx) => {
            const lastUserMessageIdx = messages.reduce(
              (lastIdxFound, message, idx) =>
                message.isUser ? idx : lastIdxFound,
              0
            );
            const isLastMessage = idx === lastUserMessageIdx;
            return (
              <ChatMessage
                key={idx}
                {...m}
                lastQuestionRef={isLastMessage ? lastQuestionRef : undefined}
              />
            );
          })}
          {pending && <Loading />}
        </StyledMessagesWrapper>
      </Scrollbar>
    </MotionContainer>
  );
};

export { ChatMessages };
