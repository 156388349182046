/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IHttpHelperProps<T> {
  useLoader?: boolean;
  setData?: React.Dispatch<React.SetStateAction<T>>;
}

export interface IHttpHelperExtendedProps<T> extends IHttpHelperProps<T> {
  id?: string | number;
}

export interface IHttpMutateHelperProps<T, U>
  extends IHttpHelperExtendedProps<T> {
  body?: U;
}

export interface ICustomApiError extends Partial<IErrorBody> {
  statusCode?: number;
  message?: string;
}

export interface IErrorBody {
  section: number;
  code: number;
  key: keyof typeof APIErrors;
}

export enum APIErrors {
  ExpiredToken,
  CodeDoesNotExists,
  CodeExpired,
}

export interface JsonApiError {
  id?: string;
  status?: string;
  code?: string;
  title?: string;
  detail?: string;
  links?: JsonApiLinks;
}

export interface JsonApiLinks {
  [key: string]: string | Record<string, string>;
}

export interface ITrySubRequestInput {
  accessToken: string;
  blueprint: {
    requestId: string;
    uri: string;
    action: string;
    headers?: HeadersInit;
    body?: string;
  }[];
  toBeDeserialized?: boolean;
}

export interface JsonApiResponse extends Record<string, any> {
  jsonapi?: {
    version: string;
    meta: Record<string, any>[];
  };
  data: Record<string, any>[];
  errors: JsonApiError[];
  meta: {
    count: number;
    [key: string]: any;
  };
  links?: JsonApiLinks;
  included?: Record<string, any>[];
}

export interface JsonApiResource extends Record<string, any> {
  id: string;
  type: string;
  langcode: string;
  status: boolean;
}

export declare type PathAlias = {
  alias: string;
  pid: number;
  langcode: string;
};

export interface JsonApiResourceWithPath extends JsonApiResource {
  path: PathAlias;
}

export type AccessToken = {
  token_type: string;
  expires_in: number;
  access_token: string;
  refresh_token?: string;
};

export interface DrupalNode extends JsonApiResourceWithPath {
  drupal_internal__nid: number;
  drupal_internal__vid: number;
  changed: string;
  created: string;
  title: string;
  default_langcode: boolean;
  sticky: boolean;
  count?: number;
  type: string;
}

export interface DrupalParagraph extends JsonApiResource {
  drupal_internal__id: number;
  drupal_internal__revision_id: number;
}

export interface DrupalBlock extends JsonApiResource {
  info: string;
}

export interface DrupalMedia extends JsonApiResource {
  drupal_internal__mid: string;
  drupal_internal__vid: string;
  changed: string;
  created: string;
  name: string;
}

export interface DrupalFile extends JsonApiResource {
  drupal_internal__fid: string;
  changed: string;
  created: string;
  filename: string;
  uri: {
    value: string;
    url: string;
  };
  filesize: number;
  filemime: string;
  resourceIdObjMeta?: DrupalFileMeta;
}

export interface DrupalFileMeta {
  alt?: string;
  title?: string;
  width: number;
  height: number;
}

export interface DrupalTaxonomyTerm extends JsonApiResourceWithPath {
  drupal_internal__tid: string;
  changed: string;
  default_langcode: boolean;
  name: string;
  description: string;
  weight: number;
}

export interface DrupalUser extends JsonApiResourceWithPath {
  drupal_internal__uid: string;
  changed: string;
  created: string;
  default_langcode: boolean;
  name: string;
}

export interface DrupalView<T = Record<string, any>[]> {
  id: string;
  results: T;
  meta: JsonApiResponse['meta'];
  links: JsonApiResponse['links'];
}

export interface EntityFieldConfig {
  type: string;
  id: string;
  links: { self: { href: string } };
  attributes: {
    langcode: string;
    status: boolean;
    dependencies: { config: string[]; module: string[] };
    third_party_settings: object;
    drupal_internal__id: string;
    field_name: string;
    entity_type: string;
    bundle: string;
    label: string;
    description: string;
    required: boolean;
    translatable: boolean;
    default_value: any[];
    default_value_callback: string;
    settings: {
      handler: string;
      handler_settings: {
        target_bundles: Record<string, string>;
        sort: {
          field: string;
          direction: string;
        };
        auto_create: boolean;
        auto_create_bundle: string;
      };
    };
    field_type: string;
  };
}

export interface JsonApiResourceBodyRelationship {
  data: {
    type: string;
    id: string;
  };
}

export interface JsonApiCreateFileResourceBody {
  data: {
    type?: string;
    attributes: {
      type: string;
      field: string;
      filename: string;
      file: Buffer;
    };
  };
}

export interface JsonApiStandardBody {
  entity: string;
  bundle: string;
}

export interface JsonApiUpdateResourceBody extends JsonApiStandardBody {
  input: {
    data: {
      type?: string;
      id?: string;
      attributes?: Record<string, any>;
      relationships?: Record<string, JsonApiResourceBodyRelationship>;
    };
  };
}

export interface JsonApiDeleteResponse {
  success: boolean;
}

export interface JsonApiHookInterface {
  showLoader?: () => void;
  hideLoader?: () => void;
}

export enum FilterOperatorEnum {
  CONTAINS = 'CONTAINS',
  STARTS_WITH = 'STARTS_WITH',
  EQUAL = '=',
  NOTEQUAL = '<>',
  GREATER_OR_EQUAL = '>=',
  GREATER = '>',
  LESS_OR_EQUAL = '<=',
  LESS = '<',
}

export interface IAskChatInput {
  query: string;
  thread: string;
}

export interface IAskChatOutput {
  message: string;
  url: string;
  thread_id: string;
}
