import { Stack, Typography } from '@mui/material';
import parse from 'html-react-parser';
import React from 'react';
import {
  StyledLogo,
  StyledStartingChatWraper,
  StyledStartingQuestion,
} from './starting-chat.styles';
import { IStartingChatProps } from './starting-chat.types';
import { useTranslations } from 'hooks';

const StartingChat = ({ onQuestionClick }: IStartingChatProps) => {
  const i18n = useTranslations().chat;
  const startingQuestions = [
    { text: 'Dammi le novità legali e fiscali <br> in materia ecologica.' },
    { text: 'Quali sono i corsi relativi alla sostenibilità ecologica?' },
  ];
  return (
    <StyledStartingChatWraper>
      <StyledLogo src="/logo/ai-icon.png" />
      <Typography variant="h3" mt={3} mb={1}>
        {i18n.title}
      </Typography>
      <Typography variant="body1">{i18n.subtitle}</Typography>

      <Stack flexDirection="row" gap={2} mt={8}>
        {startingQuestions.map((q, idx) => (
          <StyledStartingQuestion
            key={idx}
            onClick={() => onQuestionClick(q.text as string)}
            elevation={0}
          >
            <Typography variant="body1" color="#212B36">
              {parse(q.text)}
            </Typography>
          </StyledStartingQuestion>
        ))}
      </Stack>
    </StyledStartingChatWraper>
  );
};

export { StartingChat };
