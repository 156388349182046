import { Typography } from '@mui/material';
import { m } from 'framer-motion';
import { StyledLogo } from '../chat-messages/chat-messages.styles';
import { StyledMessage } from './chat-message.styles';
import { IChatMessageProps } from './chat-message.types';
import { Markdown } from 'components';
import { varBounce, varSlide } from 'components/animate';

const ChatMessage = ({
  message,
  url,
  isUser,
  lastQuestionRef,
}: IChatMessageProps) => (
  <m.div
    variants={isUser ? varBounce({ durationIn: 1.3 }).inUp : varSlide().inUp}
    ref={lastQuestionRef}
  >
    <StyledMessage isUser={isUser}>
      {!isUser && (
        <div>
          <StyledLogo src="/logo/ai-icon.png" width={50} />
        </div>
      )}
      <div style={{ width: '100%' }}>
        {url && <iframe src={url} width="100%" height={600} />}
        <Typography variant="body1" fontWeight="regular" component="span">
          <Markdown source={message} components={{ p: 'span' }}>
            ciao
          </Markdown>
        </Typography>
      </div>
    </StyledMessage>
  </m.div>
);

export { ChatMessage };
