// @mui

import { CSSProperties } from 'react';

// ----------------------------------------------------------------------

function SideBarAiIcon({
  color = 'white',
  style,
}: {
  color?: string;
  style?: CSSProperties;
}) {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M19.5829 14.1472C19.5624 14.3468 19.3935 14.3417 19.2553 14.3878C18.5848 14.6027 17.8887 14.6898 17.1823 14.9713C13.3538 16.4863 10.6462 20.018 9.79143 24.0001C8.83429 18.9789 4.95457 15.2681 0 14.2086C4.88804 13.4051 8.87524 9.38714 9.79143 4.54517C10.8305 9.35131 14.6129 13.4409 19.5829 14.1472Z"
        fill={color}
      />
      <path
        d="M25.8989 8.71652C23.8055 8.96732 22.1983 10.7587 21.794 12.76C21.3333 11.2092 20.3864 9.81185 18.9123 9.0748C18.5796 8.9059 18.1958 8.84448 17.8784 8.71652C17.73 8.6551 17.6379 8.81377 17.6891 8.46572C18.9431 8.348 20.2994 7.40622 20.9546 6.36207C21.3231 5.77858 21.4357 5.03642 21.8605 4.55017C22.0653 5.91166 22.9507 7.06841 24.0717 7.83616L25.904 8.71652H25.8989Z"
        fill={color}
      />
      <path
        d="M16.174 2.02175L14.7818 2.96353L14.0909 4.16635C13.7991 3.05054 13.1286 2.37492 12.0077 2.08317C12.9853 1.8119 13.9987 1.10045 14.0294 0C14.3468 0.184261 14.357 0.619322 14.5515 0.926423C14.8893 1.4485 15.5547 1.91939 16.174 2.02175Z"
        fill={color}
      />
    </svg>
  );
}

export default SideBarAiIcon;
