import { Box, IconButton, Stack, styled } from '@mui/material';

export const StyledChatWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 24,
  flex: 1,
  padding: theme.spacing(2),
  marginLeft: 'auto',
  display: 'flex',
  gap: theme.spacing(2),
  flexDirection: 'column',
}));

export const StyledPrompt = styled(Stack)(({ theme }) => ({
  maxHeight: 100,
  paddingInline: '10%',
  marginTop: 'auto',
  position: 'relative',
  gap: theme.spacing(1),
}));

export const StyledResetContextButton = styled(IconButton)({
  margin: 'auto',
  position: 'absolute',
  bottom: 10,
  right: 'calc(10% - 64px)',
});
