import { Box, styled } from '@mui/material';
import { IStyledMessageProps } from './chat-message.types';

export const StyledMessage = styled(Box, {
  shouldForwardProp: (prop: string) => !['isUser'].includes(prop),
})<IStyledMessageProps>(({ theme, isUser }) => ({
  marginBottom: theme.spacing(1.5),
  paddingInline: theme.spacing(4),
  paddingBlock: theme.spacing(2),
  borderRadius: theme.spacing(2),
  maxWidth: 'calc(100% - 100px)',
  display: 'flex',
  gap: theme.spacing(3),
  ...(isUser && {
    width: 'fit-content',
    maxWidth: '60%',
    marginLeft: 'auto',
    marginRight: 32,
    backgroundColor: theme.palette.grey.A200,
  }),

  '& iframe': {
    border: 0,
    padding: 0,
  },
}));
