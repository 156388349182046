import { styled, Theme, ButtonProps } from '@mui/material';
import { HIGHLIGHT_COLOR } from 'constants/data';

export const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

export const Container = styled('div')(
  ({ isCollapse }: { isCollapse: boolean | undefined }) => ({
    height: '100%',
    width: '100%',
    padding: isCollapse ? '20px 10px' : '30.7px 50px 60px 31px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    overflow: 'auto',
    overflowX: 'hidden',
  })
);

export const LogoContainer = styled('div')(
  ({ isCollapse }: { isCollapse: boolean | undefined }) => ({
    width: '100%',
    marginLeft: isCollapse ? 18 : 0,
    position: 'relative',
    cursor: 'pointer',
  })
);

export const NavBarItem = styled('div')(
  ({
    isCollapse,
    theme,
    active,
  }: {
    isCollapse: boolean | undefined;
    theme?: Theme;
    active: boolean;
  }) => ({
    width: isCollapse ? '100%' : '400px',
    display: 'flex',
    justifyContent: isCollapse ? 'center' : 'flex-start',
    alignItems: 'center',
    gap: '12px',
    color: active ? theme?.palette.grey[0] : '#919EAB',
    cursor: 'pointer',
    '& a': {
      color: active ? theme?.palette.grey[0] : '#919EAB',
    },
    '&:hover': {
      color: !active ? theme?.palette.grey[400] : theme?.palette.grey[0],
    },
  })
);

export const YellowIcon = styled('div')(
  ({
    isCollapse,
  }: // eslint-disable-next-line @typescript-eslint/ban-types
  ButtonProps & {
    isCollapse?: boolean;
  }) => ({
    height: '56px',
    width: isCollapse ? '56px' : 'unset',
    borderRadius: '50px',
    backgroundColor: HIGHLIGHT_COLOR,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...(!isCollapse && {
      marginLeft: -8,
      padding: '12px 16px',
    }),
    '&:hover': {
      backgroundColor: HIGHLIGHT_COLOR,
    },
  })
);
